<template>
  <screen-grid :full-height="true">
    <screen-card :full-height="true">
      <template v-slot:body>
        <div class="userexpress-list__list">
          <ui-data-list
            :items="userExpressMobile"
            :is-loading="isLoading"
            :is-scrolling="isScrollingUser"
            :no-more-data="noMoreDataUser"
            @click="cellClicked({ row: { id: $event.id } })"
          >
            <template v-slot:skeleton>
              <div class="userexpress-list__list__content">
                <skeleton-line height="18px" width="80%" />
                <skeleton-line height="15px" width="50%" margin-top="3px" />
              </div>
              <skeleton-icon margin-left="16px" />
            </template>
            <template v-slot:item="slotProps">
              <span
                class="userexpress-list__list__status backoffice-icons"
                :class="[`userexpress-list__list__status--${slotProps.item.status}`]"
              >
                <template v-if="slotProps.item.status === '1'"> success </template>
                <template v-else>error</template>
              </span>
              <div class="userexpress-list__list__content">
                <div class="userexpress-list__list__content__name">
                  {{ slotProps.item.firstName }} {{ slotProps.item.lastName }}
                </div>
                <div class="userexpress-list__list__content__mail">
                  {{ slotProps.item.email }}
                </div>
              </div>
              <div class="userexpress-list__list__locations">
                <span
                  class="userexpress-list__list__locations__item"
                  :class="`userexpress-list__list__locations__item--${idx}`"
                  v-for="(location, idx) in slotProps.item.locations"
                  :key="idx"
                >
                  <template v-if="idx < 2">
                    {{ location.name }}
                  </template>
                  <template v-else> {{ $t('userexpress.list.more') }} </template>
                </span>
              </div>
            </template>
          </ui-data-list>
        </div>
        <div class="userexpress-list__table">
          <ui-data-table
            :columns="columns"
            :rows="userExpress"
            :total-rows="totalRowsUser"
            :is-loading="isLoading || isUpdatingUser"
            :pagination="userExpressPagination"
            :isSelectable="false"
            @loadItems="loadUserExpress({ filters: stringifiedSearchFilters, ...$event }, false, true)"
            @onCellClick="cellClicked"
            @onSelectedRowsChanged="onSelectedRowsChanged"
          >
            <template slot="data-table-row" slot-scope="props">
              <span v-if="props.column.field === 'lastName'">
                <div class="userexpress-list__table__block">
                  <div class="userexpress-list__table__block__content">
                    <div class="userexpress-list__table__block__content__name">
                      {{ props.row['firstName'] }} {{ props.row['lastName'] }}
                    </div>
                    <span class="userexpress-list__table__block__content__mail">
                      {{ props.row['email'] }}
                    </span>
                  </div>
                </div>
              </span>
              <span v-if="props.column.field === 'locations'">
                <div class="userexpress-list__table__locations">
                  <span
                    class="userexpress-list__table__locations__item"
                    :class="`userexpress-list__table__locations__item--${idx}`"
                    v-for="(location, idx) in props.row['locations']"
                    :key="idx"
                  >
                    <template v-if="idx < 3">
                      {{ location.group ? `[${location.group}]` : '' }} {{ location.name }}
                    </template>
                    <template v-else> {{ $t('userexpress.list.more') }} </template>
                  </span>
                </div>
              </span>
              <span v-if="props.column.field === 'status'">
                <span
                  class="userexpress-list__table__status backoffice-icons"
                  :class="[`userexpress-list__table__status--${props.row['status']}`]"
                >
                  <template v-if="props.row['status'] === '1'"> success </template>
                  <template v-else>error</template>
                </span>
              </span>
              <span v-if="props.column.field === 'createdAt'">
                {{ date(props.row['createdAt']) }}
              </span>
            </template>
          </ui-data-table>
        </div>
      </template>
    </screen-card>
  </screen-grid>
</template>

<script>
import UiDataTable from '@/components/UI/DataTable.vue'
import UiDataList from '@/components/UI/DataList.vue'
import ScreenGrid from '@/components/Screen/Grid.vue'
import ScreenCard from '@/components/Screen/Card.vue'
import SkeletonLine from '@/components/Skeleton/Line.vue'
import SkeletonIcon from '@/components/Skeleton/Icon.vue'
import { onScroll } from '@/mixins/scroll.mixin'
import { formatedDate } from '@/utils/date.util'

export default {
  name: 'UserExpressListUser',
  components: {
    UiDataTable,
    UiDataList,
    ScreenGrid,
    ScreenCard,
    SkeletonLine,
    SkeletonIcon,
  },
  mixins: [onScroll],
  data() {
    return {
      stringifiedSearchFilters: '',
    }
  },
  props: {
    userExpress: {
      type: Array,
      required: true,
    },
    userExpressMobile: {
      type: Array,
      required: true,
    },
    userExpressPagination: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isUpdatingUser: {
      type: Boolean,
      required: false,
      default: false,
    },
    isScrollingUser: {
      type: Boolean,
      required: false,
      default: false,
    },
    totalRowsUser: {
      type: Number,
      required: false,
      default: 0,
    },
    noMoreDataUser: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    canRefreshData: {
      handler() {
        if (this.canRefreshData && !this.isScrollingUser && !this.noMoreDataUser) {
          this.isScrollingUser = true
        }
      },
    },
    userExpress: {
      deep: true,
      handler() {
        this.userExpress.filter(user => {
          user.vgtDisabled = user.status === '0' ? true : false
          return user
        })
      },
    },
  },
  computed: {
    noData() {
      return this.isLoading || this.noMoreDataUser
    },
    columns() {
      return [
        {
          label: this.$t('userexpress.list.user.column.userexpress'),
          field: 'lastName',
          sortable: false,
        },
        {
          label: this.$t('userexpress.list.user.column.locations'),
          field: 'locations',
          sortable: false,
        },
        {
          label: this.$t('userexpress.list.user.column.status'),
          field: 'status',
        },
        {
          label: this.$t('userexpress.list.user.column.createdAt'),
          field: 'createdAt',
          sortable: false,
        },
      ]
    },
  },
  methods: {
    date(date) {
      return formatedDate(date, 'DD/MM/YYYY', this.$i18n.locale)
    },
    cellClicked(e) {
      this.$emit('cellClicked', {
        name: 'UserExpressId',
        id: e.row.id,
      })
    },
    onSelectedRowsChanged(params) {
      this.$emit('onSelectedRowsChanged', params)
    },
    loadUserExpress(tableParams, resetUserExpress = false, updating = false) {
      this.$emit('loadItems', {
        tableParams,
        resetUserExpress,
        updating,
      })
    },
  },
}
</script>

<style lang="scss">
.userexpress-list {
  padding-bottom: $button-min-height + $gutter-mobile * 2;

  @media (min-width: $screen-sm) {
    padding-bottom: 0;
  }

  &__list {
    position: relative;
    margin-bottom: -16px;

    @media (min-width: $screen-sm) {
      margin: 0 (-$gutter-tablet) (-$gutter-tablet);
    }

    @media (min-width: $screen-md) {
      display: none;
    }

    &__content {
      flex: 1;
      &__name {
        text-transform: capitalize;
        font-weight: 600;
      }
    }

    &__locations {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 5px;
      align-items: flex-end;
      margin-left: 10px;
      overflow: hidden;

      &__item {
        display: none;
        border-radius: 50px;
        background-color: map-get($generic-color-variants, 'data');
        padding: 2px 8px;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: $white;
        font-size: $font-size-sm;

        &--0,
        &--1,
        &--2 {
          display: block;
        }
      }
    }

    &__status {
      margin-right: $gutter-mobile;
      color: map-get($generic-color-variants, 'error');
      &--1 {
        color: map-get($generic-color-variants, 'success');
      }
    }
  }
  &__table {
    display: none;

    @media (min-width: $screen-md) {
      display: block;
      height: 100%;
      overflow-y: auto;
    }

    &__block {
      display: flex;
      align-items: center;

      &__content {
        display: flex;
        flex-direction: column;
        &__name {
          text-transform: capitalize;
          font-weight: 600;
        }
        &__mail {
          @include text-ellipsis(1, 14px);

          margin-top: 3px;
        }
      }
    }

    &__locations {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 5px;

      &__item {
        display: none;
        border-radius: 50px;
        background-color: map-get($generic-color-variants, 'data');
        padding: 2px 8px;
        color: $white;
        font-size: $font-size-sm;

        &--0,
        &--1,
        &--2,
        &--3 {
          display: block;
        }
      }
    }

    &__status {
      margin-left: $gutter-mobile;
      color: map-get($generic-color-variants, 'error');
      &--1 {
        color: map-get($generic-color-variants, 'success');
      }
    }

    &__link {
      @include full-link;
    }
  }
}
</style>
