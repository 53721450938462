<template>
  <div>
    <div class="user-express-list-filters">
      <div class="user-express-list-filters__status">
        <div class="user-express-list-filters__status__title">
          {{ $t('userexpress.list.campaign.filters.status.label') }}
        </div>
        <div class="user-express-list-filters__status__radios">
          <ui-radio
            class="user-express-list-filters__status__radios__radio"
            id="status-all"
            value="ALL"
            name="status"
            :reverse="true"
            v-model="filters.status"
          >
            {{ $t('userexpress.list.campaign.filters.status.all') }}
          </ui-radio>
          <ui-radio
            class="user-express-list-filters__status__radios__radio"
            id="status-enabled"
            value="ENABLED"
            name="status"
            :reverse="true"
            v-model="filters.status"
          >
            {{ $t('userexpress.list.campaign.filters.status.enabled') }}
          </ui-radio>
          <ui-radio
            class="user-express-list-filters__status__radios__radio"
            id="status-disabled"
            value="DISABLED"
            name="status"
            :reverse="true"
            v-model="filters.status"
          >
            {{ $t('userexpress.list.campaign.filters.status.disabled') }}
          </ui-radio>
        </div>
      </div>

      <div class="user-express-list-filters__dropdown">
        <div class="user-express-list-filters__dropdown__title">
          {{ $t('userexpress.list.campaign.filters.selector.label') }}
        </div>
        <ui-dropdown
          id="modal-simple-field-dropdown"
          v-model="modelData"
          label="firstName"
          track-by="id"
          :options="userExpressFiltered"
          :placeholder="$t('userexpress.list.campaign.filters.selector.placeholder')"
          :show-label="false"
          :no-absolute="true"
        >
          <template v-slot:label="{ label }"> {{ label.firstName }} {{ label.lastName }} </template>
          <template v-slot:option="{ option }"> {{ option.firstName }} {{ option.lastName }} </template>
        </ui-dropdown>
      </div>

      <div class="user-express-list-filters__buttons">
        <div class="user-express-list-filters__buttons__reset">
          <ui-button button="secondary" icon="reset" :label="$t('locations.list.filters.reset')" @click="reset" />
        </div>
        <div class="user-express-list-filters__buttons__close">
          <ui-button button="primary" v-close-popover icon="close" :label="$t('locations.list.filters.close')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UiRadio from '@/components/UI/Radio.vue'
import UiButton from '@/components/UI/Button.vue'
import UiDropdown from '@/components/UI/Dropdown.vue'

const getStringifiedStatus = value => {
  switch (value) {
    case 'ALL':
      return ''
    case 'ENABLED':
      return 'status:1'
    case 'DISABLED':
      return 'status!1'
    default:
      return ''
  }
}

const defaultFilters = {
  status: 'ENABLED',
}

export default {
  name: 'UserExpressListFilters',
  components: {
    UiRadio,
    UiButton,
    UiDropdown,
  },
  props: {
    change: {
      type: String,
      required: false,
      default: '',
    },
    userExpress: {
      type: Array,
      required: true,
      default: () => [],
    },
    userExpressFilteredActive: {
      type: Array,
      required: true,
      default: () => [],
    },
    userExpressFilteredDeactivate: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      filters: {},
      modelData: '',
    }
  },
  created() {
    this.filters = {
      ...defaultFilters,
    }
  },
  computed: {
    stringifiedSearch() {
      return [getStringifiedStatus(this.filters.status)].filter(filter => filter && filter.length > 0).join(',')
    },
    userExpressFiltered() {
      if (this.filters.status === 'ENABLED') {
        return this.userExpressFilteredActive
      } else if (this.filters.status === 'DISABLED') {
        return this.userExpressFilteredDeactivate
      } else {
        return this.userExpress
      }
    },
  },
  watch: {
    filters: {
      deep: true,
      handler: 'onUpdateFilters',
    },
    modelData: {
      deep: true,
      handler: 'userExpressSelected',
    },
  },
  methods: {
    onUpdateFilters() {
      this.$emit('onUpdateFilters', this.stringifiedSearch)
    },
    userExpressSelected() {
      this.$emit('onUpdateUserExpressFilter', this.modelData.id)
    },
    reset() {
      this.filters = { status: 'ALL' }
      this.modelData = ''
    },
  },
}
</script>

<style lang="scss" scoped>
.user-express-list-filters {
  display: flex;
  flex-direction: column;
  gap: $gutter-tablet;
  margin: 0 auto;

  &__status {
    display: flex;
    flex-direction: column;

    &__title {
      margin-bottom: $gutter-mobile/2;
      font-weight: $font-weight-title;
    }

    &__radios {
      display: flex;
      gap: $gutter-tablet;
      justify-content: space-between;
    }
  }
  &__dropdown {
    display: flex;
    flex-direction: column;

    &__title {
      margin-bottom: $gutter-mobile/2;
      font-weight: $font-weight-title;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
  }
}
</style>
