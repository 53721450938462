<template>
  <div class="ui-data-search ui-data-search--fixed-search">
    <div
      class="ui-data-search__container"
      :class="{
        'ui-data-search__container--fixed-container':
          elementFixed && scrollDirection === 'up' && (isMobile || isTablet),
      }"
    >
      <div
        class="ui-data-search__container__wrapper"
        :class="{
          'ui-data-search__container__wrapper--fixed-wrapper':
            elementFixed && scrollDirection === 'up' && (isMobile || isTablet),
        }"
      >
        <section class="ui-data-search__container__wrapper__section">
          <skeleton-input
            v-if="isLoading && !isScrolling"
            width="100%"
            class="ui-data-search__container__wrapper__section__input"
          />
          <template v-else>
            <ui-input
              id="input-search"
              :label="$t(`${view}.list.search`)"
              v-model="search"
              icon="search"
              design="minimalist"
              class="ui-data-search__container__wrapper__section__input"
              :disabled="isDisabled"
              @input="onInput"
            />
            <div class="ui-data-search__container__wrapper__section__mobile-filter">
              <v-popover
                v-if="withFilter"
                popoverInnerClass="popover-inner no-overflow"
                placement="bottom"
                :autoHide="true"
                container=".ui-data-search"
              >
                <ui-button
                  button="secondary"
                  icon="filter"
                  :label="$t(`${view}.list.button.filter`)"
                  class="ui-data-search__container__wrapper__section__mobile-filter__button"
                />
                <template slot="popover">
                  <div class="ui-data-search__container__wrapper__section__mobile-filter__popover">
                    <slot name="filterSlot"></slot>
                  </div>
                </template>
              </v-popover>
            </div>
          </template>
        </section>
        <skeleton-input
          v-if="withFilter && isLoading && !isScrolling"
          width="112px"
          margin-left="24px"
          class="ui-data-search__container__wrapper__filter"
        />

        <v-popover
          v-else-if="withFilter"
          popoverInnerClass="popover-inner no-overflow"
          placement="bottom-end"
          :autoHide="true"
          container=".ui-data-search"
        >
          <ui-button
            button="primary"
            icon="filter"
            :label="$t(`${view}.list.button.filter`)"
            class="ui-data-search__container__wrapper__filter"
          />
          <template slot="popover">
            <div class="ui-data-search__container__wrapper__filter__popover">
              <slot name="filterSlot"></slot>
            </div>
          </template>
        </v-popover>
        <skeleton-input
          v-if="isLoading && !isScrolling"
          width="250px"
          margin-left="24px"
          class="ui-data-search__container__wrapper__cta"
        />
        <ui-button
          v-else-if="withAddButton && isUserAuthorized(creationRoles)"
          button="cta"
          icon="add"
          :label="$t(`${view}.list.button.add`)"
          class="ui-data-search__container__wrapper__cta"
          :variant="productLine"
          @click="onClick"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UiInput from '@/components/UI/Input.vue'
import UiButton from '@/components/UI/Button.vue'
import SkeletonInput from '@/components/Skeleton/Input.vue'
import { isUserAuthorized } from '@/config/permissions.config'
import { debounce } from '@/utils/func.util'
import { mapState } from 'vuex'

export default {
  name: 'UiDataSearch',
  components: {
    UiInput,
    UiButton,
    SkeletonInput,
  },
  props: {
    view: {
      type: String,
      required: true,
    },
    isMobile: {
      type: Boolean,
    },
    isTablet: {
      type: Boolean,
    },
    elementFixed: {
      type: Boolean,
    },
    creationRoles: {
      type: Array,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isScrolling: {
      type: Boolean,
      default: false,
    },
    scrollDirection: {
      type: String,
      default: '',
    },
    withFilter: {
      type: Boolean,
      default: false,
    },
    withAddButton: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: '',
      isUserAuthorized,
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.user.currentUser,
    }),
    productLine() {
      return this.$route.meta.productLine || 'data'
    },
  },
  methods: {
    onInput: debounce(function (value) {
      this.$emit('search', value)
      this.$emit('input', value)
    }, 300),
    onClick() {
      this.$emit('add')
    },
  },
}
</script>

<style lang="scss" scoped>
.ui-data-search {
  &--fixed-search {
    margin-top: (-$gutter-mobile);
    height: calc(#{$input-min-height} + #{$gutter-mobile} * 2);

    @media (min-width: $screen-sm) {
      margin: (-$gutter-tablet) (-$gutter-tablet) 0;
      height: calc(#{$input-min-height} + #{$gutter-tablet} * 2);
    }

    @media (min-width: $screen-xl) {
      margin: (-$gutter-desktop) (-$gutter-desktop) 0;
      height: calc(#{$input-min-height} + #{$gutter-desktop} * 2);
    }
  }
  &__container {
    top: 0;
    transition: top $transition-duration-default $transition-effect-default;
    padding: $gutter-mobile 0;

    @media (min-width: $screen-sm) {
      padding: $gutter-tablet 0;
    }

    @media (min-width: $screen-xl) {
      padding: $gutter-desktop 0;
    }
    &--fixed-container {
      position: fixed;
      top: $topbar-height;
      right: 0;
      left: 0;
      z-index: 1;
      border-bottom: 1px solid var(--border-color);
      background-color: var(--bg-color);
      padding: $gutter-mobile 0;

      @media (min-width: $screen-sm) {
        padding: $gutter-tablet 0;
      }
    }
    &__wrapper {
      @include container;

      @media (min-width: $screen-sm) {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      &--fixed-wrapper {
        @media (min-width: $screen-sm) {
          padding-right: $gutter-tablet * 2;
          padding-left: $gutter-tablet * 2;
        }

        @media (min-width: $screen-xl) {
          padding-right: $gutter-desktop * 2;
          padding-left: $gutter-desktop * 2;
        }
      }
      &__section {
        position: relative;
        width: 100%;

        @media (min-width: $screen-sm) {
          flex: 1;
        }
        &__mobile-filter {
          @media (min-width: $screen-sm) {
            display: none;
          }
          &__button {
            position: absolute;
            top: 0;
            right: 1px;
          }
          &__popover {
            display: flex;
            flex: 1;
            padding: $gutter-mobile;
            width: 100vw;
            min-width: 280px;
          }
        }
      }
      &__filter {
        display: none;
        &__popover {
          display: flex;
          flex: 1;
          padding: $gutter-mobile;
          min-width: 280px;

          @media (min-width: $screen-sm) {
            padding: $gutter-tablet;
            min-width: 340px;
          }
          &__dropdown {
            margin-top: $gutter-mobile;

            @media (min-width: $screen-sm) {
              margin-top: $gutter-tablet;
            }
            &:first-child {
              margin-top: 0;
            }
          }
        }

        @media (min-width: $screen-md) {
          display: inline-flex;
          margin-left: $gutter-tablet;
        }

        @media (min-width: $screen-xl) {
          display: inline-flex;
          margin-left: $gutter-desktop;
        }
      }
      &__cta {
        position: fixed;
        right: $gutter-mobile;
        bottom: calc(#{$navigation-height} + #{$gutter-mobile});
        z-index: 2;

        @media (min-width: $screen-sm) {
          position: relative;
          right: initial;
          bottom: initial;
          margin-left: $gutter-tablet;
        }

        @media (min-width: $screen-xl) {
          margin-left: $gutter-desktop;
        }
      }
    }
  }
}
</style>
