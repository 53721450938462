<template>
  <div class="userexpress-list" @scroll.passive="onScroll($event.srcElement, tabsElement)">
    <screen-header
      class="userexpress-list__header"
      :title="$t('userexpress.list.title')"
      :breadcrumbs="breadcrumbs"
      :is-loading="isLoading"
      :right-content="true"
    >
      <div class="userexpress-list__header__create" v-if="isLoading && isUserAuthorized(creationRoles)">
        <skeleton-input />
      </div>
      <ui-button
        class="userexpress-list__header__create-campaign"
        button="cta"
        variant="data"
        icon="add"
        :label="$t('userexpress.list.button.createCampaign')"
        :mobile-toggle="true"
        @click="addCampaignConfirm"
        v-else-if="isUserAuthorized(creationRoles)"
      />
      <div class="userexpress-list__header__create" v-if="isLoading && isUserAuthorized(creationRoles)">
        <skeleton-input />
      </div>
      <ui-button
        class="userexpress-list__header__create-user"
        button="primary"
        variant="data"
        icon="add_person"
        :label="$t('userexpress.list.button.createUserExpress')"
        :mobile-toggle="true"
        @click="addUserExpress"
        v-else-if="isUserAuthorized(creationRoles)"
      />
    </screen-header>
    <screen-container direction="column">
      <screen-tabs
        :tabs="tabs"
        :element-fixed="elementFixed"
        :current-tab="currentTab || 'campaign'"
        :is-loading="isLoading"
        @mounted="onTabsMounted"
        @click="setCurrentTab"
      />
    </screen-container>

    <screen-container :full-height="true" class="userexpress-list--no-padding">
      <!-- User Section -->
      <list-user
        :user-express="userExpress"
        :user-express-mobile="userExpressMobile"
        :user-express-pagination="userExpressPagination"
        :isLoading="isLoading"
        :is-updating-user="isUpdatingUser"
        :is-scrolling-user="isScrollingUser"
        :total-rows-user="totalRowsUser"
        :no-more-data-user="noMoreDataUser"
        @cellClicked="goToCellLink"
        @loadItems="loadUserExpress"
        @onSelectedRowsChanged="getUserExpressSelectedRows"
        v-if="currentTab === 'user'"
      />

      <!-- Campaign Section -->
      <list-campaign
        :campaign="campaign"
        :campaign-mobile="campaignMobile"
        :campaign-pagination="campaignPagination"
        :user-express="userExpress"
        :user-express-filtered-active="userExpressFilteredActive"
        :user-express-filtered-deactivate="userExpressFilteredDeactivate"
        :is-loading="isLoading"
        :is-updating-campaign-data="isUpdatingCampaign"
        :is-scrolling-campaign="isScrollingCampaign"
        :total-rows-campaign="totalRowsCampaign"
        :no-more-data-campaign="noMoreDataCampaign"
        @cellClicked="goToCellLink"
        @loadItems="loadCampaign"
        @editCampaignStatut="editCampaignStatut"
        v-if="currentTab === 'campaign' || !currentTab"
      />
    </screen-container>

    <modal-simple-field
      v-if="isEditing && editingModal === 'field'"
      :apply-label="$t('userexpress.list.campaign.modal.apply')"
      :title="$t('userexpress.list.campaign.modal.title')"
      :is-updating="isUpdating || !hasUserExpressSelectedRows"
      :data="modal.data"
      :label="modal.label"
      :obj-key="modal.objKey"
      :parameters="modal.parameters"
      :searchable="false"
      :description="$tc('userexpress.list.campaign.modal.description', userExpressSelectedRows.length || 1)"
      @save="addCampaign"
      @closed="closedModal"
      @tagsUpdate="getUserExpressSelectedRows"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { notif } from '@/mixins/notification.mixin'
import ScreenHeader from '@/components/Screen/Header.vue'
import ScreenContainer from '@/components/Screen/Container.vue'
import ScreenTabs from '@/components/Screen/Tabs.vue'
import UiButton from '@/components/UI/Button.vue'
import SkeletonInput from '@/components/Skeleton/Input.vue'
import ListUser from './ListUser.vue'
import ListCampaign from './ListCampaign.vue'
import ModalSimpleField from '@/components/Modal/SimpleField.vue'
import { onScroll } from '@/mixins/scroll.mixin'
import { userRoles } from '@/config/permissions.config'
import { isUserAuthorized } from '@/config/permissions.config'

const initialModal = () => ({
  objKey: null,
  data: null,
  label: null,
  parameters: {
    type: 'text',
    mode: 'input',
    required: false,
    number: null,
    options: [],
  },
})
export default {
  name: 'UserExpressList',
  components: {
    ScreenHeader,
    ScreenContainer,
    ScreenTabs,
    UiButton,
    SkeletonInput,
    ListUser,
    ListCampaign,
    ModalSimpleField,
  },
  mixins: [onScroll, notif],
  data() {
    return {
      isLoading: false,
      isUpdating: false,
      isUpdatingUser: false,
      isScrollingUser: false,
      noMoreDataUser: false,
      totalRowsUser: 0,
      isUpdatingCampaign: false,
      isScrollingCampaign: false,
      noMoreDataCampaign: false,
      totalRowsCampaign: 0,
      isPublishing: false,
      isEditing: false,
      editingModal: '',
      modal: initialModal(),
      userRoles,
      isUserAuthorized,
      creationRoles: [userRoles.groupAdmin],
      tabsElement: null,
      hasUserExpressSelectedRows: false,
      userExpressSelectedRows: [],
      filters: {},
    }
  },
  created() {
    if (!this.$route.params.currentTab) {
      this.setCurrentTab('campaign')
    }
  },
  async mounted() {
    await Promise.all([this.loadUserExpress({ resetUserExpress: true }), this.loadCampaign({ resetCampaign: true })])
  },
  computed: {
    ...mapState({
      userExpress: state => state.userexpress.userExpress,
      userExpressMobile: state => state.userexpress.userExpressMobile,
      userExpressPagination: state => state.userexpress.userExpressPagination,
      campaign: state => state.userexpress.campaign,
      campaignMobile: state => state.userexpress.campaignMobile,
      campaignPagination: state => state.userexpress.campaignPagination,
    }),
    ...mapGetters({
      userExpressFilteredActive: 'userexpress/userExpressFilteredActive',
      userExpressFilteredDeactivate: 'userexpress/userExpressFilteredDeactivate',
    }),
    currentTab() {
      return this.$route.params.currentTab
    },
    breadcrumbs() {
      return [
        {
          label: this.$t('userexpress.list.breadcrumb'),
          route: {
            name: 'UserExpress',
          },
        },
      ]
    },
    tabs() {
      return [
        {
          label: this.$t('userexpress.list.tab.campaign'),
          key: 'campaign',
        },
        {
          label: this.$t('userexpress.list.tab.user'),
          key: 'user',
        },
      ]
    },
    userExpressAuthorized() {
      return this.userExpress.filter(user => user.status === '1' && user.campaignActive !== true)
    },
  },
  methods: {
    ...mapActions({
      getUserExpress: 'userexpress/getUserExpress',
      getCampaign: 'userexpress/getCampaign',
      createCampaign: 'userexpress/createCampaign',
      updateCurrentCampaign: 'userexpress/updateCurrentCampaign',
    }),
    async editCampaignStatut({ payload, filters }) {
      await this.updateCurrentCampaign({ payload, filters })
      await this.loadUserExpress({ resetUserExpress: true })
    },
    addCampaignConfirm() {
      this.modal.objKey = 'userExpress'
      this.modal.label = {
        label: '',
        placeholder: this.$t('userexpress.list.campaign.modal.placeholder'),
      }
      this.modal.data = []
      this.modal.parameters = {
        dropdown: {
          label: 'lastName',
          trackBy: 'id',
          customLabel: 'firstName lastName',
        },
        mode: 'list',
        options: this.userExpressAuthorized,
        required: true,
        type: 'text',
      }
      this.isEditing = true
      this.editingModal = 'field'
      this.$nextTick(() => {
        this.$modal.show('modal-simple-field')
      })
    },
    async addCampaign() {
      this.isUpdating = true
      try {
        const promises = this.userExpressSelectedRows.map(userExpress => {
          return this.createCampaign({ userExpressId: userExpress.id, userExpressLocations: userExpress.locations })
        })
        await Promise.all(promises)
        this.notificationSuccess('campaign', this.userExpressSelectedRows.length, 'create')
      } catch (err) {
        this.notificationError()
      }
      this.closedModal()
      if (this.currentTab !== 'campaign') {
        this.setCurrentTab('campaign')
      }
      await this.getCampaign({ resetCampaign: true, tableParams: this.filters })
      await this.loadUserExpress({ resetUserExpress: true })
      this.isUpdating = false
    },
    addUserExpress() {
      this.$router.push({ name: 'UserExpressCreate' })
    },
    goToCellLink(e) {
      this.$router.push({ name: e.name, params: { id: e.id } })
    },
    getUserExpressSelectedRows(params) {
      params.length > 0 ? (this.hasUserExpressSelectedRows = true) : (this.hasUserExpressSelectedRows = false)
      const paramsWithoutDisabled = params.filter(param => {
        if (param.vgtDisabled !== true) {
          return param
        }
      })
      this.userExpressSelectedRows = paramsWithoutDisabled
    },
    async loadUserExpress({ tableParams = null, resetUserExpress = false, updating = false }) {
      if (updating) {
        this.isUpdatingUser = true
      } else {
        this.isLoading = true
      }

      tableParams = { size: 1000, ...tableParams }
      await this.getUserExpress({ tableParams, resetUserExpress })

      if (this.userExpress.length > 0) {
        this.totalRowsUser = this.userExpressPagination.nbResults
      } else {
        this.noMoreDataUser = true
      }
      this.isLoading = false
      this.isScrollingUser = false
      this.isUpdatingUser = false
    },
    async loadCampaign({ tableParams = null, resetCampaign = false, updating = false, search = '' }) {
      this.filters = tableParams
      if (updating) {
        this.isUpdatingCampaign = true
      } else {
        this.isLoading = true
      }

      await this.getCampaign({ resetCampaign, tableParams, search })

      if (this.campaign.length > 0) {
        this.totalRowsCampaign = this.campaignPagination.nbResults
      } else {
        this.noMoreDataCampaign = true
      }
      this.isLoading = false
      this.isScrollingCampaign = false
      this.isUpdatingCampaign = false
    },
    onTabsMounted(element) {
      this.tabsElement = element
    },
    setCurrentTab(tab) {
      this.$router.replace({ ...this.$route.name, params: { ...this.$route.params, currentTab: tab } })
    },
    closedModal() {
      this.isEditing = false
      this.isUpdating = false
      this.editingModal = ''
      this.modal = initialModal()
    },
  },
}
</script>

<style lang="scss">
.userexpress-list {
  padding-bottom: $button-min-height + $gutter-mobile * 2;

  @media (min-width: $screen-sm) {
    padding-bottom: 0;
  }

  &__header {
    &__create-user {
      position: fixed;
      right: $gutter-mobile;
      bottom: calc(#{$navigation-height} + #{$gutter-mobile});
      z-index: 2;

      @media (min-width: $screen-sm) {
        position: relative;
        right: initial;
        bottom: initial;
        margin-left: $gutter-tablet;
      }

      @media (min-width: $screen-xl) {
        margin-left: $gutter-desktop;
      }
    }
    &__create-campaign {
      position: fixed;
      right: $gutter-mobile;
      bottom: calc(#{$navigation-height} + #{$gutter-mobile * 2} + #{$button-min-height});
      z-index: 2;

      @media (min-width: $screen-sm) {
        position: relative;
        right: initial;
        bottom: initial;
        margin-left: $gutter-tablet;
      }

      @media (min-width: $screen-xl) {
        margin-left: $gutter-desktop;
      }
    }
  }

  &--no-padding {
    padding-top: 0 !important;
  }
}
</style>
